import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahTransferStokHeader = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [inputTanggalTransferStok, setInputTanggalTransferStok] = useState(
    new Date()
  );
  const [jenisTransfer, setJenisTransfer] = useState("");
  const [keterangan, setKeterangan] = useState("");

  // Data Child
  const [barangs, setBarangs] = useState([]);
  const [totalTransaksi, setTotalTransaksi] = useState(0);
  const [reloadListStokChild, setReloadListStokChild] = useState(0);
  const [listStokChild, setListStokChild] = useState([
    {
      kodeBarang: "",
      kuantitasStok: "",
      keterangan: "",
    },
  ]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisTransferOption = ["GUDANG KE TOKO", "TOKO KE GUDANG"];

  let barangOptions = barangs.map((barang) => ({
    label: `${barang.kodeBarang} - ${barang.namaBarang}`,
  }));

  const getBarangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/barangs`, {
      _id: user.id,
      token: user.token,
    });
    setBarangs(response.data);
  };

  useEffect(() => {
    getBarangsData();
  }, []);

  const saveTransferStokHeader = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && jenisTransfer.length !== 0) {
      setLoadingButton(true);
      try {
        setLoadingButton(true);
        let savedTransferStokHeader = await axios.post(
          `${tempUrl}/saveTransferStokHeader`,
          {
            tanggalTransferStok: inputTanggalTransferStok,
            jenisTransfer,
            keterangan,

            listStokChild,

            userIdInput: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        setLoadingButton(false);
        navigate(
          `/daftarTransferStok/transferStok/${savedTransferStokHeader.data.id}`
        );
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        } else {
          alert(error.response.data.message);
        }
      }
      setLoadingButton(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Daftar Transfer Stok</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveTransferStokHeader}>
        <Card>
          <Card.Header>Daftar Transfer Stok</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. Transfer <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalTransferStok}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setInputTanggalTransferStok(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jenis Transfer <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisTransferOption}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={error && jenisTransfer.length === 0 && true}
                          helperText={
                            error &&
                            jenisTransfer.length === 0 &&
                            "Jenis Transfer harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisTransfer(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      onChange={(e) => {
                        setKeterangan(e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarTransferStok")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <LoadingButton
                variant="contained"
                loading={loadingButton}
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </LoadingButton>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Detail Transaksi</Card.Header>
          <Card.Body>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  bgcolor: "info.light",
                  textTransform: "none",
                  marginRight: 2,
                  marginBottom: 2,
                }}
                onClick={() => {
                  listStokChild.push({
                    kodeBarang: "",
                    kuantitasStok: "",
                    keterangan: "",
                  });
                  setReloadListStokChild(Math.floor(Math.random() * 1000));
                }}
              >
                {"+ Input Transaksi"}
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Barang
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kuantitas
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Keterangan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Hapus
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listStokChild.map((stokChild, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={barangOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              sx={{ width: "200px" }}
                              {...params}
                            />
                          )}
                          value={stokChild.kodeBarang}
                          onChange={(e, value) => {
                            if (value) {
                              stokChild.kodeBarang = value.label;
                              setReloadListStokChild(
                                Math.floor(Math.random() * 1000)
                              );
                            } else {
                              stokChild.kodeBarang = "";
                              setReloadListStokChild(
                                Math.floor(Math.random() * 1000)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={stokChild.kuantitasStok}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            stokChild.kuantitasStok = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            let tempTotalTransaksi = 0;
                            for (let stokChildData of listStokChild) {
                              let tempKuantitasStok = parseFloat(
                                stokChildData.kuantitasStok
                              );

                              if (isNaN(tempKuantitasStok)) {
                                tempKuantitasStok = 0;
                              }

                              tempTotalTransaksi += tempKuantitasStok;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListStokChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={stokChild.keterangan}
                          onChange={(e) => {
                            stokChild.keterangan = e.target.value.toUpperCase();
                            setReloadListStokChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            listStokChild.splice(index, 1);

                            let tempTotalTransaksi = 0;
                            for (let stokChildData of listStokChild) {
                              let tempKuantitasStok = parseInt(
                                stokChildData.kuantitasStok
                              );

                              if (isNaN(tempKuantitasStok)) {
                                tempKuantitasStok = 0;
                              }

                              tempTotalTransaksi += tempKuantitasStok;
                            }
                            setTotalTransaksi(tempTotalTransaksi);

                            setReloadListStokChild(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>
                      <Form.Control
                        value={totalTransaksi.toLocaleString("de-DE")}
                        disabled
                        readOnly
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
        </Card>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahTransferStokHeader;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
